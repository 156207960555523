@import 'styles/variables.less';

.container {
  padding: 0px 24px 24px 24px;

  .banner_carousel_container {
    width: 100%;

    :global(.ant-image) {
      width: 100%;
    }

    // .banner {
    //   height: 100;
    // }
  }

  .card_container {
    background: @primary-background-color;
    border: 1px solid #efefef;
    // border-radius: 10px;
    border-left: 3px solid #ffb211;
    padding: 20px 20px 10px 20px;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);

    .image {
      height: 70px;
      width: 70px;
    }
  }
}

.title_image {
  height: 30px;
  width: 30px;
}

@media (max-width: 575px) {
  .container {
    padding: 20px;
  }
}
.item_container {
  // height: calc(50vh - 60px);
  width: 100%;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: cover;

  .header_container {
    display: inline-flex;
    flex-direction: column;

    .description {
      margin-top: -15px;
      text-align: right;
    }
  }

  .item_description_container {
    max-width: 80%;
    background: rgba(0, 0, 0, 0.5);
    border-left: 4px solid @secondary-color;
    border-radius: 0px 10px 10px 0px;
    margin-top: 25px;
    padding: 20px;
  }

  .antspacealigncenter {
    align-items: stretch !important;
  }

  .text_direction > div:nth-child(2) {
    margin-top: -8px !important;
  }
}
.arrow {
  border-radius: 4px;
  border: none;
  transition: all 0.5s;
  cursor: pointer;
}

.arrow {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.arrow:after {
  content: '»';
  position: absolute;
  opacity: 0;
  right: -20px;
  transition: 0.5s;
}

.arrow:hover {
  padding-right: 24px;
  padding-left: 8px;
}

.arrow:hover:after {
  opacity: 1;
  right: 10px;
}
