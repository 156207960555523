@import 'styles/variables.less';

.container {
  min-height: calc(100vh - 150px);
  padding: 32px;

  .inner_container {
    margin-bottom: 32px;

    .card_container {
      display: flex;
      flex-direction: column;
      border: 1px solid @border-1;
      border-radius: 16px;
      padding: 0px;
      overflow: hidden;

      :global(.ant-image) {
        width: auto;
        margin: auto;
      }

      .banner_container {
        position: relative;
        margin: auto;

        .banner {
          height: 280px;
          overflow: hidden;
        }

        .author_container {
          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
          padding: 24px;
          background: rgba(255, 255, 255, 0.3);
          border-top: 1px solid rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(12px);

          .icon {
            color: rgba(255, 255, 255, 0.5);
            font-size: 24px;
          }
        }
      }

      .content_container {
        display: flex;
        flex-direction: column;
        padding: 32px 24px;

        .title {
          margin-bottom: 12px;
        }

        .body {
          margin-bottom: 32px;
        }

        .icon {
          color: @primary-color;
          font-size: 18px;
        }
      }

      .read_post {
        &.web {
          display: block;
        }

        &.mobile {
          display: none;
        }

        .icon {
          color: @secondary-color;
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    min-height: calc(100vh - 72px);
    padding: 20px;

    .inner_container {
      margin-bottom: 20px;

      .card_container {
        .banner_container {
          .banner {
            height: 200px;
          }

          .author_container {
            padding: 14px 24px;
          }
        }

        .content_container {
          padding: 20px;

          .title {
            margin-bottom: 6px;
          }

          .body {
            margin-bottom: 20px;
          }
        }

        .read_post {
          &.web {
            display: none;
          }

          &.mobile {
            display: block;
            border: 1px solid @border-1;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            padding: 10px;
            text-align: center;
          }

          .icon {
            color: @secondary-color;
            font-size: 12px;
          }
        }
      }
    }
  }
}