@import 'styles/variables.less';

.container {
  min-height: calc(100vh - 150px);
  padding: 32px;

  .inner_container {
    padding: 0;
    border: 1px solid @border-1;
    border-radius: 16px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: contain;

    .video_details_container {
      padding: 24px 24px 5px;
    }

    .video_container {
      padding: 0px 24px;

      .video_carousel_container {
        :global(.slick-list) {
          width: 92%;
          margin: auto;
        }

        :global(.slick-slide) {
          width: auto !important;
          margin-right: 10px;
        }

        :global(.slick-arrow) {
          background: #646464;
          width: 30px;
          height: 100%;
          line-height: 110px;
          top: 10px;

          &:hover,
          &:focus {
            background-color: #2a2a2a;
          }
        }

        :global(.slick-prev) {
          color: #FFFFFF !important;
          font-size: 24px;
          left: 0px;
          z-index: 1;
          border-radius: 8px 0px 0px 8px;
        }

        :global(.slick-next) {
          color: #FFFFFF !important;
          font-size: 24px;
          right: 0px;
          z-index: 1;
          border-radius: 0px 8px 8px 0px;
        }

        :global(.slick-dots) {
          bottom: -28px;

          li button {
            background: @secondary-color;
          }
        }

        .video_thumbnail_container {
          position: relative;
          height: 110px;

          .video_thumbnail_img {
            height: 110px;
            width: auto;
          }

          .video_play_btn_container {
            position: absolute;
            bottom: 0;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.7);
            box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
            text-align: center;

            .video_play_btn {
              color: @text-color-2;
              font-size: 37.5px;
            }
          }

          .video_title {
            position: absolute;
            bottom: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    .image_container {
      .carousel_container {
        text-align: center;

        .image {
          height: 200px;
          width: auto;
        }

        .video {
          height: 200px;
          width: auto;
        }
      }
    }

    .details_container {
      padding: 32px 24px;

      .body {
        color: @text-color-2;
      }

      .icon {
        color: @primary-color;
        font-size: 18px;
      }

      .comments_container {
        .send_icon {
          color: @text-color-3;
          cursor: not-allowed;

          &.enabled {
            color: @primary-color;
            cursor: pointer;
          }
        }

        .comments_list_container {
          .comments_list_item_container {
            border-bottom: 1px solid @border-3;
            padding-bottom: 10px;

            .comment_delete {
              font-size: 13px;
              color: red;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    min-height: calc(100vh - 72px);
    padding: 20px;

    .inner_container {
      .details_container {
        padding: 20px;
      }
    }
  }
}