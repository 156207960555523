// @import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './theme.less';
@import './CerebriSans/style.css';
@import './PoorRichard/style.css';
@import './variables.less';
@import './custom.less';
@import 'react-quill/dist/quill.snow.css';

body {
  margin: 0;
  font-family: 'CerebriSans Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','palace-script-mt-regular'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
