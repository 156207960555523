@import 'styles/variables.less';

.container {
  white-space: pre-wrap;

  .paperclip_icon {
    color: @text-color-3;
    font-size: 16px;
  }

  .header_right_container {
    font-size: 18px;
    color: @text-color-3;

    .header_right_icon {
      cursor: pointer;

      &.pinned {
        color: @primary-color;
      }
    }
  }

  .docs_container {
    .docs_carousel_container {
      text-align: center;

      :global(.slick-arrow) {
        background-color: #00000057;
        width: 30px;
        height: 60px;
        line-height: 65px;
        top: 44%;

        &:hover,
        &:focus {
          background-color: #2a2a2a;
        }
      }

      :global(.slick-prev) {
        color: #FFFFFF !important;
        font-size: 24px;
        left: 0px;
        z-index: 1;
      }

      :global(.slick-next) {
        color: #FFFFFF !important;
        font-size: 24px;
        right: 0px;
        z-index: 1;
      }
    }

    .deleteonimage {
      width: auto !important;
      position: relative;
      margin: 0px auto;

      .image {
        height: 200px;
        width: auto;
        margin: 0 5px;
      }

      .video {
        height: 200px;
        width: auto;
      }
    }

    .delete {
      background-color: transparent;
      width: 20px;
      padding: 5px 25px;
      color: rgb(247, 14, 14);
      display: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    .deleteonimage:hover .delete {
      display: block;
    }
  }

  .custom_pagination {
    display: none;
  }

  .post_details_container {
    margin-top: 20px;
    border-bottom: 1px solid #F8F8F8;
    padding: 10px 1.5px;

    .post_details_item {
      display: flex;
      align-items: center;
      color: @text-color-3;

      &.liked {
        color: @primary-color;
      }
    }
  }

  .send_icon {
    color: @text-color-3;
    cursor: not-allowed;

    &.enabled {
      color: @primary-color;
      cursor: pointer;
    }
  }

  .comments_list_container {
    .comments_list_item_container {
      border-bottom: 1px solid @border-3;
      padding-bottom: 10px;
    }
  }

  .comment_delete {
    font-size: 13px;
    color: red;
  }

  .deletePost {
    color: red;
  }
}

.likes_modal_container {
  .avatar {
    background: @primary-color;
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}

@media (max-width: 575px) {
  .container {
    padding: 20px;

    .docs_container {
      .docs_carousel_container {
        :global(.slick-arrow) {
          display: none !important;
        }
      }

      .deleteonimage {
        .video {
          height: auto;
          width: 100%;
        }
      }

      .delete {
        display: block !important;
      }
    }

    .custom_pagination {
      display: block;
      text-align: center;
      margin-top: 5px;
    }
  }
}