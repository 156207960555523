@import 'styles/variables.less';

@media (min-width: 576px) {
  .container {
    .minus_icon_container {
      margin-top: -20px;
    }
  
  }
}
.dragger{
  height: 63% !important;
}
.dragger > span{
  padding: 0px !important;
}

