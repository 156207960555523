@import 'styles/variables.less';

.container {
  .areas_container {
    :global(.ant-radio-button-wrapper) {
      border-radius: 5px;
      border: 1px solid @border-1  !important;
      overflow: hidden;
      height: 26px;
      padding: 5px 5px 5px 5px;
      line-height: initial;
      margin-top: 6px;
      font-size: 12px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}