@font-face {
  font-family: 'CerebriSans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('CerebriSans Regular'),
    url('CerebriSans Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'CerebriSans SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('CerebriSans SemiBold'),
    url('CerebriSans SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'CerebriSans Bold';
  font-style: normal;
  font-weight: normal;
  src: local('CerebriSans Bold'), url('CerebriSans Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'palace-script-mt-regular';
  font-style: normal;
  font-weight: normal;
  src: local('palace-script-mt-regular'),
    url('PalaceScriptMT.ttf') format('truetype');
}

.cs-regular {
  font-family: 'CerebriSans Regular' !important;
}

.cs-medium {
  font-family: 'CerebriSans SemiBold' !important;
}

.cs-bold {
  font-family: 'CerebriSans Bold' !important;
}
