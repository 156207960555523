@import 'styles/variables.less';

.carousel_container {
  :global(.slick-arrow) {
    background-color: #00000057;
    width: 30px;
    height: 60px;
    line-height: 65px;
    top: 44%;

    &:hover,
    &:focus {
      background-color: #2a2a2a;
    }
  }

  :global(.slick-prev) {
    color: #FFFFFF !important;
    font-size: 24px;
    left: 0px;
    z-index: 1;
  }

  :global(.slick-next) {
    color: #FFFFFF !important;
    font-size: 24px;
    right: 0px;
    z-index: 1;
  }

  &.hide_left_arrow {
    :global(.slick-prev) {
      display: none !important;
    }
  }

  &.hide_right_arrow {
    :global(.slick-next) {
      display: none !important;
    }
  }

  .survey_container {
    position: relative;
    height: calc(100vh - 110px);
    width: auto;
    padding: 30px 32px 0;
    white-space: pre-wrap;
    background-image: url('../../images/bg1.png');
    background-repeat: no-repeat;
    background-size: cover;

    .date {
      color: '#AEAEAE !important';
    }

    .description_container {
      background: rgba(0, 0, 0, 0.3);
      border-left: 4px solid @secondary-color;
      border-radius: 0px 10px 10px 0px;
      padding: 20px 20px 20px 20px;
      width: 600px;//500px650
      
      .polls {
        width: 70%;
      }
    }

    .poll_container {
      padding: 5px 9px;
      background-color: #fefae0;
      border-radius: 4px;
    }

    .bottom_text {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }

  .survey_result_container {
    height: calc(100vh - 110px);
    width: auto;
    padding: 30px 32px 0;
    white-space: pre-wrap;
    background-image: url('../../images/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .date {
      color: @text-color-white;
    }

    .description_container {
      background: rgba(0, 0, 0, 0.3);
      border-left: 4px solid @secondary-color;
      border-radius: 0px 10px 10px 0px;
      padding: 20px 20px 20px 20px;

      .polls {
        width: 70%;
      }
    }

    .poll_container {
      padding: 5px 9px;
      background-color: #fefae0;
      border-radius: 4px;
    }

    .tag_container {
      border-radius: 16px;
    }

    .stats_container {
      height: 100%;
    }

    :global(.ant-collapse-item) {
      border-top: 1px solid @border-1;

      :global(.ant-collapse-header) {
        padding-left: 0px;
        padding-right: 0px;
      }

      :global(.ant-collapse-content-box) {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        padding-bottom: 20px;
      }
    }

    :global(.ant-collapse-item-active) {
      :global(.ant-collapse-header) {
        .shifts_header {
          color: @primary-color;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .carousel_container {
    .survey_container {
      // min-height: calc(100vh - 102px);
      // height: auto;
      // padding: 15px;
      // border-left: 4px solid @secondary-color;
      // border-radius: 0px 10px 10px 0px;
      // margin: 15px;
      // width: auto !important;
      min-height: calc(100vh - 102px);
      height: auto;
      padding: 15px;
      border-radius: 0px 10px 10px 0px;
      margin: 15px;
      width: auto !important;

      .description_container {
        background:  rgba(0, 0, 0, 0.3);
        border-left: 4px solid @secondary-color;
        border-radius: 0px 10px 10px 0px;
        padding: 20px 20px 20px 20px;
        width: auto !important;
        // margin-left: 12px;

        .score{
          font-size: 6px;
        }
      }

      .top_score{
        
        padding-right: 148px;
      }

      .bottom_text {
        bottom: 25px;
      }
    }

    .survey_result_container {
      min-height: calc(100vh - 102px);
      height: auto;
      padding: 15px;
      border-left: 4px solid @secondary-color;
      border-radius: 0px 10px 10px 0px;
      margin: 15px;
      width: auto !important;

      .description_container {
        background: transparent;
        border-left: none;
        border-radius: 0px;
        padding: 0px;

        .polls {
          width: 100%;
        }
      }
    }
  }
}