@import 'styles/variables.less';

.container {
  position: relative;
  background: @primary-background-color;
  border: 1px solid @border-1;
  border-radius: 0px;
  padding: 20px;
  height: 100%;

  &.top_border {
    border-top: 3px solid @primary-color;
  }

  &.left_border {
    border-left: 3px solid @primary-color;
  }

  .title_container {
    border-bottom: 1px solid @border-1;

    .more_icon {
      color: @text-color-3;
    }
  }
}