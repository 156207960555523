@import 'styles/variables.less';

.container {
  padding: 24px;
  white-space: pre-wrap;

  .top_container {
    background: #FEFAE0;
    border: 1px solid #F2F4F7;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

    .header_container {
      border-bottom: 1px solid @primary-color;
      padding-bottom: 12px;

      .main_status {
        padding: 5px 20px;
        background: #ebf8d5;
        border-radius: 16px;
        color: #027A48;
        font-family: 'CerebriSans SemiBold';
      }
    }

    .documents {
      box-sizing: border-box;
      padding: 0px 10px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #D0D5DD;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-top: 6px;
    }

    .like_icon {
      color: @primary-color;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .comments_container {
    .send_icon {
      color: @text-color-3;
      cursor: not-allowed;

      &.enabled {
        color: @primary-color;
        cursor: pointer;
      }
    }

    .comments_list_container {
      .comments_list_item_container {
        border-bottom: 1px solid @border-3;
        padding-bottom: 10px;

        .comment_delete {
          font-size: 13px;
          color: red;
        }
      }
    }
  }

  .realted_suggestion {
    border: 1px solid @border-2;
    border-radius: 10px;
    padding: 5px 10px 5px 8px;

    .dot {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #D0D5DD;
    }
  }

  .area_container {
    cursor: pointer;

    &.active {
      background-color: #FF725E;
      color: #FFFFFF;
    }
  }

  .idea_container {
    cursor: pointer;
    width: 100%;

    &.active {
      background-color: #FFF1F1;
    }

    .status_container {
      border-radius: 16px;
    }
  }
}

.likes_modal_container {
  .avatar {
    background: @primary-color;
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
.deletePost {
  color: red;
}