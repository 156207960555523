@import 'styles/variables.less';

.container {
  min-height: 100%;
  padding: 24px 32px;

  .card_container {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

    .more_icon {
      color: @text-color-3;
    }

    .like_icon {
      color: @primary-color;
      font-size: 18px;
    }

    .customtext {
      font-size: 14px;
      margin-left: 4px;
    }
  }

  .areas_container {
    :global(.ant-radio-button-wrapper) {
      border-radius: 5px;
      border: 1px solid @border-1 !important;
      overflow: hidden;
      height: 26px;
      padding: 5px 5px 5px 5px;
      line-height: initial;
      margin-top: 6px;
      font-size: 12px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .areas_mobile_container {
    display: none;
  }
}

.formStyles {
  margin-bottom: -30px;
}

.deletePost {
  color: red;
}

.stared {
  color: #FFB211;
}

.likes_modal_container {
  .avatar {
    background: @primary-color;
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}

@media (max-width: 575px) {
  .container {
    padding: 20px;

    .areas_container {
      display: none;
    }

    .areas_mobile_container {
      display: block;
    }
  }
}
// .infoColor{
//   background-color: #e6f4ff;
//   border: 1px solid #91caff;
//   border-radius: 4px;
// }
.infoColor>span
{
  // color:#1677ff !important;
  font-size: 16px !important;
}