@import 'styles/variables.less';

.container {
  position: relative;
  min-height: 100vh;
  max-width: 1440px;
  margin: auto;
  background: @layout-background;

  .content_layout {
    margin-top: 100px;
    margin-bottom: 20px;
    background: @layout-background;
    padding-left: 8px;
    padding-right: 8px;

    .sider_container {
      position: fixed;
      right: 8px;
      background: transparent;
    }

    .content {
      &.left_sider {
        padding-left: 0px;
      }

      &.right_sider {
        padding-right: 8px;
      }
    }
  }

  .footer {
    padding: 5px 50px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 11px;
    background: #f0f2f5;
  }

  .floating_btn_container {
    display: none;
  }
}

@media (min-width: 1440px) {
  .container {
    .content_layout {
      .sider_container {
        right: calc((100% - 1440px) /2 + 8px);
      }
    }
  }
}

@media (min-width: 576px) {
  .container {
    .content_layout {
      width: calc(100% - 310px);
    }
  }
}

@media (max-width: 575px) {
  .container {
    .content_layout {
      margin-top: 72px;
      margin-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;

      .content {
        &.right_sider {
          padding-right: 0px;
        }
      }
    }

    .sider_container {
      display: none;
    }

    .footer {
      display: none;
    }

    .floating_btn_container {
      display: block;
      position: fixed;
      right: 20px;
      bottom: 20px;
      text-align: right;

      .floating_btn {
        background-color: @secondary-color;
      }
    }
  }
}