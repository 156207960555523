.container {
  padding: 16px 24px;

  .avatar {
    width: 50px;
    height: 50px;
    background: #FEFAE0;
    border-radius: 200px;
    line-height: 50px;
  }

  .email {
    color: red
  }
}

.imageWidth {
  width: 80% !important;
}

@media (max-width: 575px) {
  .container {
    padding: 20px;
  }
}