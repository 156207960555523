@import 'styles/variables.less';

.container {
  padding: 0px 24px;

  .item_container {
    width: 100%;
    padding: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    // height: 250px;
    // box-shadow: 0px 1px 0px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    // box-shadow: -3px 0px 3px 0px rgba(16, 24, 40, 0.1);

    .header_container {
      display: inline-flex;
      flex-direction: column;

      .description {
      margin-top: -15px;
      text-align: right;
    }
    }
  }

  .banner_carousel_container {
    width: 100%;

    :global(.ant-image) {
      width: 100%;
    }

    .banner {
      height: 200px;
    }
  }

  .banner_space{
    margin-top: 20px;

    .title_container {
      .award_style {
        font-family: 'palace-script-mt-regular';
        font-size: 45px;
        font-weight: bold;
        letter-spacing: 4px;
        line-height: 45px;
      }
    }
  }

  .card_container {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.83), rgba(255, 255, 255, 0.83)), @primary-background-color;
    border: 1px solid @border-1;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    // border-radius: 8px;
    padding: 16px 24px 16px;

    .image {
      height: 50px;
      width: 50px;
    }

    .display_pic_container {
      position: relative;
      // height: 240px;
      // display: flex;
      // align-items: center;
      overflow: hidden;
      // top: -7px;

      .user_details_container {
        position: absolute;
        left: 0;
        bottom: -0.5px;
        background: @primary-background-color;
        box-shadow: 10px 5px 15px rgba(0, 0, 0, 0.15);
        // border-radius: 0px 20px 0px 0px;
        padding: 8px;
        min-width: 120px;
      }
    }

    .footer {
      .icon {
        color: @primary-color;
        font-size: 18px;
      }
      margin-top:-12px !important;
      margin-bottom: -12px !important;
    }
  }

}

.image1 {
  height: 50px;
  width: 50px;
}
.divider{
  margin-top: -7px !important;
}
.confetti{
  // opacity: 0.5 !important;
  // z-index: 0 !important;
  // top:4% !important;
  // height: 14;
  padding: 15px 32px;
}

@media (max-width: 575px) {
  .container {
    padding: 10px;

    .banner_space{
      margin-top: 15px;

      .title_container {
        flex-direction: column-reverse;
        align-items: flex-start;

        .award_style {
          font-size: 40px;
          line-height: 40px;
        }
      }
    }
  }
}