@import 'styles/variables.less';

.container {

  .view_quiz_result_question{
    width: 600px;
    margin-left: 28px;
    margin-bottom: -10px;
  }

  .view_quiz_result_option{
    width: 600px;
    margin-left: 44px;
    margin-top: 1px;
  }
  
  
  .areas_container {
    :global(.ant-radio-button-wrapper) {
      border-radius: 5px;
      border: 1px solid @border-1  !important;
      overflow: hidden;
      height: 26px;
      padding: 5px 5px 5px 5px;
      line-height: initial;
      margin-top: 6px;
      font-size: 12px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  // background-image: url("../quiz/images/bg1.png");
  // background-repeat:no-repeat;
  // background-size: 100% 100%;
}

@media (max-width: 575px) {
  .container {
    padding: 20px;

    .areas_container {
      display: none;
    }

    .areas_mobile_container {
      display: block;
      padding-left: 75px;
    }

    .view_quiz_result_question,.view_quiz_result_option{
      width: 334px;
      margin: 0;
    }
  

    
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px;

    .areas_container {
      display: none;
    }

    .areas_mobile_container {
      display: block;
      padding-left: 75px;
    }
  }
}

