@import 'styles/variables.less';

.container {
  min-height: calc(100vh - 150px);
  padding: 15px 32px;

  .row_container {
    border-radius: 10px;
    overflow: hidden;

    .left_container {
      position: relative;
      display: flex;
      height: 100%;
      align-items: center;
      padding: 10px 30px;
      background: #383838;
      height: 60vh;
      overflow: hidden;

      :global(.ant-image) {
        position: absolute;
        top: 35px;
        right: 42px;
      }

      .image {
        height: 140px;
        width: 90px;
      }
    }
   

    .image_container {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      max-height: 60vh;
      overflow: hidden;
      background-color: #383838;

      .images_horizontal_scroll {
        display: flex;
        overflow-x: auto;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .image {
          height: auto;
          width: 100%;
        }
      }

      :global(.ant-carousel) {
        height: 100%;
      }

      :global(.ant-carousel .slick-dots li button) {
        background: @primary-color;
      }

      .carousel_container {
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;

        :global(.ant-image) {
          display: block;
        }

        .image {
          height: auto;
          width: 100%;
        }
      }
    }
  }
  .image1{
    height: 50px;
    width: 50px;
  }
  .image2{
    height: 20% !important;
  }
  .details_container {
    .icon {
      color: @primary-color;
      font-size: 18px;
    }

    .comments_container {
      .send_icon {
        color: @text-color-3;
        cursor: not-allowed;

        &.enabled {
          color: @primary-color;
          cursor: pointer;
        }
      }

      .comments_list_container {
        .comments_list_item_container {
          border-bottom: 1px solid @border-3;
          padding-bottom: 10px;

          .comment_delete {
            font-size: 13px;
            color: red;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    min-height: calc(100vh - 72px);
    padding: 20px;

    .row_container {
      .left_container {
        height: auto;
        min-height: 60vh;

        :global(.ant-image) {
          top: 15px;
          right: 20px;
        }

        .image {
          height: 70px;
          width: 45px;
        }
      }
    }
  }
}
.confetti{
  padding: 15px 32px;
}
