@import 'styles/variables.less';

.container {
  height: 100vh;
  padding: 50px;

  .row_container {
    height: 100%;

    .right_col_container {
      background-image: url('../../images/bird.svg');
      background-repeat: no-repeat;
      background-position: center;

      .right_col_inner_container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        .web_logo {
          position: absolute;
          top: 0;
          right: 0;
        }

        .mobile_logo {
          display: none;
        }

        .title {
          margin-bottom: 20px;
          text-align: center;
        }

        .mobile_banner {
          display: none;
        }

        .button {
          background-color: @secondary-color;
          color: #FFF;
          height: 54px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    padding: 15px;

    .row_container {
      .right_col_container {
        .right_col_inner_container {
          justify-content: space-evenly;
          align-items: flex-start;

          .web_logo {
            display: none;
          }

          .mobile_logo {
            display: block;
          }

          .title {
            margin-bottom: 0;
            text-align: unset;
          }

          .mobile_banner {
            display: block;
          }

          .button {
            width: 80%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}