.container {

  :global(.ant-input-affix-wrapper) {
    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }

  :global(.ant-input-affix-wrapper-focused) {
    border-color: transparent;
    box-shadow: none;
  }

  :global(.ant-input) {
    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }
}