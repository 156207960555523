@import 'styles/variables.less';

.container {
  .menu_container {
    .menu_item {
      display: flex;
      align-items: center;
      padding: 11px 18px;

      .menu_item_icon {
        color: @text-color-1;
        font-size: 16px;
        margin-right: 13px;
      }

      &.selected {
        background-color: @secondary-color;

        .menu_item_icon {
          color: #FFFFFF !important;
        }

        .menu_item_label {
          color: #FFFFFF !important;
        }
      }
    }
  }
}