@font-face {
  font-family: 'PoorRichard';
  font-style: normal;
  font-weight: normal;
  src: local('PoorRichard'),
    url('PoorRichard.TTF') format('truetype');
}

.poor-richard {
  font-family: 'PoorRichard' !important;
}
