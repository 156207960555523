.container {
  padding: 9px 32px;
  white-space: pre-wrap;
  border: 1px solid #ffb211;
  border-radius: 4px;

  .quotation_container {
    background: #F8F8F8;
    border-left: 5px solid #FFB211;
    padding: 28px 28px 18px 80px;
  }
  .areas_container {
    :global(.ant-radio-button-wrapper) {
      border-radius: 5px;
      border: 1px solid #ece0e0  !important;
      overflow: hidden;
      height: 26px;
      padding: 5px 5px 5px 5px;
      line-height: initial;
      margin-top: 6px;
      font-size:12px;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
// .spacecard{
//   margin-left: 8px;
// }
