.container {
  padding: 24px 32px;
  white-space: pre-wrap;

  .quotation_container {
    background: #F8F8F8;
    border-left: 5px solid #FFB211;
    padding: 28px 28px 18px 80px;
  }
}

@media (max-width: 575px) {
  .container {
    padding: 20px;

    .top_container {
      flex-direction: column-reverse;
    }

    .quotation_container {
      padding: 18px 20px;
    }
  }
}