@import 'styles/variables.less';

.carousel_container {
  :global(.slick-arrow) {
    background-color: #00000057;
    width: 30px;
    height: 60px;
    line-height: 65px;
    top: 44%;

    &:hover,
    &:focus {
      background-color: #2a2a2a;
    }
  }

  :global(.slick-prev) {
    color: #ffffff !important;
    font-size: 24px;
    left: 0px;
    z-index: 1;
  }

  :global(.slick-next) {
    color: #ffffff !important;
    font-size: 24px;
    right: 0px;
    z-index: 1;
  }

  :global(.slick-dots) {
    bottom: 0px;
  }

  .slide1 {
    .outer_container {
      position: relative;
      height: calc(100vh - 110px);
      width: 100%;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      /* Chrome, Safari, Opera, and other WebKit-based browsers */
      &::-webkit-scrollbar {
        display: none;
      }

      .inner_container {
        position: relative;
        width: 50%;
        padding: 0px 0 0 32px;
        margin-top: -8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .phoenix_bird_container {
          position: absolute;
          top: 10%;
          right: 0;
          opacity: 0.5;

          .phoenix_bird {
            height: 250px;
            width: 150px;
          }
        }

        .focus_areas_container {
          display: flex;
          align-items: center;

          .focus_areas_card {
            background-color: @primary-background-color;
            border-radius: 10px;
            width: 150px;
            padding: 30px 5px;
          }
        }
      }

      .custom_inner_container {
        display: none;
      }

      .right_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 60px 20px;

        .bg_transparent {
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: -1;
        }

        .data_container {
          max-width: 80%;

          .opt_icon {
            color: @text-color-white;
          }

          .opt_text {
            color: @text-color-white;
          }
        }
      }

      .custom_right_container {
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        // padding: 60px 20px;
        clip-path: none;

        .bg_transparent {
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: -1;
        }
      }
    }
  }
  .container {
    .item_container {
      height: calc(100vh - 110px);
      width: 100%;
      background-repeat: no-repeat;
      // background-size: cover;
      background-size: 100% 100%;
      background-position: center;

      .play-button {
        position: absolute; /* Position the button absolutely */
        top: 50%; /* Adjust top position */
        left: 50%; /* Adjust left position */
        transform: translate(-50%, -50%); /* Center the button */
        width: 50px; /* Adjust width */
        height: 50px; /* Adjust height */
        background-color: rgba(
          255,
          255,
          255,
          0.5
        ); /* Semi-transparent background */
        border: none;
        border-radius: 50%; /* Make the button round */
        cursor: pointer;
      }

      .item_description_container {
        max-width: 80%;
        background: rgba(0, 0, 0, 0.5);
        border-left: 4px solid @secondary-color;
        border-radius: 0px 10px 10px 0px;
        margin-top: 25px;
        padding: 20px;
      }

      .antspacealigncenter {
        align-items: stretch !important;
      }

      .text_direction > div:nth-child(2) {
        margin-top: -8px !important;
      }

      .bg_transparent {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }

      .item_row_container {
        height: calc(100vh - 110px);
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        /* Chrome, Safari, Opera, and other WebKit-based browsers */
        &::-webkit-scrollbar {
          display: none;
        }

        .incline {
          position: absolute;
          height: 100%;
          width: 100%;

          &.left {
            clip-path: polygon(0 0, 40% 0, 60% 100%, 0 100%);
          }

          &.right {
            clip-path: polygon(0 0, 60% 0, 40% 100%, 0 100%);
          }
        }
        .left_container {
          // padding: 60px 90px 20px 32px;
          padding: 5px 90px 20px 32px;

          .icon {
            font-size: 30px;
            color: @secondary-color;
          }
        }
        .right_container {
          padding: 5px 32px 20px 110px;
          overflow: hidden;

          .icon {
            font-size: 30px;
            color: @primary-color;
          }

          .opt_icon {
            color: @text-color-white;
          }
        }

        .custom_right_container {
          padding: 5px 32px 20px 110px;
          overflow: hidden;

          .icon {
            font-size: 30px;
            color: @primary-color;
          }

          .opt_icon {
            color: @text-color-white;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .slide1 {
    .outer_container {
      .right_container {
        position: absolute;
        top: 0;
        right: 0;
        clip-path: ellipse(100% 100% at 100% 50%);
      }
    }
  }
}

@media (max-width: 575px) {
  .carousel_container {
    .container {
      .item_container {
        height: calc(100vh - 72px);

        .item_title {
          font-size: 24px !important;
        }

        .item_description_container {
          max-width: 100%;
          margin-top: 10px;
          padding: 10px;

          .description {
            font-size: 14px !important;
          }
        }

        .item_row_container {
          .incline {
            display: none;
          }
          .left_container {
            padding: 20px 30px 0;

            .opt_icon {
              color: @text-color-white;
            }

            .opt_text {
              color: @text-color-white;
            }
          }
          .right_container {
            padding: 0px 30px 20px;

            .right_title {
              display: none;
            }
          }
        }
      }
    }

    .slide1 {
      .outer_container {
        .inner_container {
          width: 100%;
        }
        .right_container {
          width: 100%;
          padding: 20px;
          margin-top: 20px;

          .bg_transparent {
            z-index: 0;
          }

          .data_container {
            z-index: 30;
          }
        }
      }
    }
  }
}
