@import 'styles/variables.less';

.container {
  position: fixed;
  z-index: 2;
  width: 100%;
  background: @primary-background-color;
  padding: 0px;
  border-bottom: 3px solid @secondary-color;
  max-width: 1440px;

  .row_container {
    padding: 0 20px;

    .menu_container {
      height: 100%;
      line-height: 71px;

      .menu_item {
        display: block;
        height: 100%;
        margin-right: 0px;
        padding-left: 12px;
        padding-right: 12px;

        .menu_item_icon {
          color: @secondary-color;
          font-size: 16px;
          margin-right: 10px;
        }

        &.selected {
          background-color: @secondary-color;

          .menu_item_icon {
            color: #FFFFFF !important;
          }

          .menu_item_label {
            color: #FFFFFF !important;
          }
        }
      }
    }

    :global(.ant-image-img) {
      margin: 0 !important;
    }

    .antimage {
      margin-top: -14px !important;
    }
  }


  .bottom_container {
    height: 20px;
    background: @primary-color;
  }
}

@media only screen and (max-width: 1280px) {
  .row_container {
    padding: 0 15px !important;

    .menu_container {
      .menu_item {
        font-size: 12px;
        padding-left: 7px !important;
        padding-right: 7px !important;

        .menu_item_icon {
          font-size: 12px !important;
          margin-right: 5px !important;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .menu_container {
    line-height: 14px !important;
    font-size: 8px !important;

    .menu_item {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .menu_item_icon {
      font-size: 11px !important;
      margin-right: 5px !important;
    }
  }
}

@media (min-width: 576px) {
  .container {
    .menu_container {
      display: flex;
    }

    .right_container {
      .tata_logo {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .hamburger_menu {
        display: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    .menu_container {
      display: none;
    }

    .right_container {
      .tata_logo {
        display: none;
      }

      .hamburger_menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }

    .bottom_container {
      display: none;
    }
  }
}