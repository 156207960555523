@import 'styles/variables.less';

.container {
  .avatar {
    background: @primary-color;
    color: #fff;
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}