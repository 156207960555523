@import 'styles/variables.less';

.container {
  :global(.ant-modal-close-x) {
    height: 44px;
    width: 44px;
    line-height: 44px;
  }

  :global(.ant-modal-header) {
    padding: 10px;
  }

  :global(.ant-modal-body) {
    padding: 10px;
    max-height: 350px;
    overflow: scroll;
  }

  .avatar {
    background: @primary-color;
    color: #fff;
    height: 18px;
    width: 18px;
    line-height: 18px;
  }
  
}