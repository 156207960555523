@import 'styles/variables.less';

.container {
  margin-top: 4px;

  .text_white {
    :global(.ant-progress-text) {
      color: @text-color-white;
    }
  }
}